import axios from 'axios';
//require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` })

var api_URL= 'http://192.168.1.115:7001'
if (process.env.NODE_ENV === "development") {
  api_URL = 'http://192.168.1.115:7001'
}
else if (process.env.NODE_ENV === "production") {
  api_URL = 'https://betner.co:7001'
}
const instance = axios.create({
  baseURL: api_URL
});

// const instance = axios.create({
//     baseURL: 'http://localhost:7001'
// });
console.log(process.env.NODE_ENV);
const AUTH_TOKEN = "123"
const System = "Betner"
instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default instance;
