import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabbannerlist: [],
        }

    }
    componentDidMount() {
        instance.get("/api/v1/tabbannerlist?System=" + Systems + "")
            .then((res) => {
                console.log("tabbannerlist", res.data);
                if (res.data.status === 200) {
                    this.setState({
                        tabbannerlist: res.data.message
                    });
                }
                else {
                }
            });

    }

    render() {
        const { tabbannerlist } = this.state
        return (
            <> <Swiper
            spaceBetween={10}
            slidesPerView={4}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 1500 }}
            loop={true}
                >
                    {tabbannerlist.length > 0 ? (
                        tabbannerlist.map((item, index) => (
                            <SwiperSlide>
                                <img src={item.path} alt={item.alt || `Slide ${index + 1}`} />
                            </SwiperSlide>))) : (
                        <p>No items available</p>
                    )}

                </Swiper>
            </>
        );
    }
}
export default App;
